const defaultTitle = 'Angeplus';

export const PAGE_TITLE = {
  defaultTitle,
  landingPage: defaultTitle.concat(' | Várias soluções em um só lugar!'),
  home: defaultTitle.concat(' | Início'),
  shortcut: defaultTitle.concat(' | Passo a passo de instalação do Angeplus'),
  reconciliation: {
    index: defaultTitle.concat(' | Garanta seus benefícios'),
  },
};
