import { logger } from '@ferreri/minato-logger-next';
import { QueryClientProvider } from '@ferreri/minato-react-utils';
import { AppLayout, angeplus } from '@ferreri/prever-component-lib';
import { Encode_Sans } from 'next/font/google';

import { SETTINGS } from '../config/settings';
import { PAGE_TITLE } from '../utils/pageTitle';
import { queryClient } from '../utils/queryClient';

const encodeSans = Encode_Sans({
  subsets: ['latin'],
  weight: [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '700',
    '800',
    '900',
  ],
  variable: '--font-family-01',
});

logger.setBasePath(SETTINGS.public.basePath);

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppLayout
        theme={angeplus}
        defaultTitle={PAGE_TITLE.defaultTitle}
        fontFamily={encodeSans.variable}
        productName="Angeplus"
        projectName="Hub"
        tmsToken={SETTINGS.public.tmsToken}
      >
        <Component {...pageProps} />
      </AppLayout>
    </QueryClientProvider>
  );
}
