import { handleError } from '@ferreri/minato-error-handler-react';
import { QueryClient, ResponseError } from '@ferreri/minato-react-utils';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError(error: ResponseError) {
        handleError(error?.response?.data?.error);
      },
    },
    queries: {
      onError(error: ResponseError) {
        handleError(error?.response?.data?.error);
      },
    },
  },
});
